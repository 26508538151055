import React from "react";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";
import "../../../Components/Sidebar/Sidebar.css";
import EmailLink from "../../../Components/Links/EmailLink";

function TreasurerSideBar() {
  const navItems = [
    {
      title: "About",
      to: "/departments/treasurer",
      isLink: false,
    },
    {
      title: "Delinquent Tax List",
      to: "/files/departments/treasurer/delinquent-tax-list-new.pdf",
      isLink: true,
    },

    {
      title: "Pay Business Taxes",
      to: "https://secureinstantpayments.com/sip/reports/account_selector.php?FromPopup=1&Merchant=25470&AddrReq=x&Account=",
      isLink: true,
    },
    {
      title: "Pay Property Taxes Online",
      to: "https://ironcounty.billtrax.com/biller/quick-pay",
      isLink: true,
    },
    {
      title: "Pay Mobile Homes",
      to: "https://secureinstantpayments.com/sip/reports/account_selector.php?FromPopup=1&Merchant=25470&AddrReq=x&Account=",
      isLink: true,
    },
    {
      title: "Receive Tax Notice by Email",
      to: "https://ironcounty.billtrax.com/home",
      isLink: true,
    },
    {
      title: "Request Change of Address",
      to: "/departments/treasurer/change-of-address",
      isLink: false,
    },
    {
      title: "Search Property Accounts",
      to: "https://eagleweb.ironcounty.net:8443/treasurer/web/",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Nicole Rosenberg, Iron County Treasurer
            <br />
            PO Box 369
            <br />
            <TextWebLink link="https://goo.gl/maps/dWeYhAwocsaNHEyT9">
              68 S 100 E, Parowan, UT 84761
            </TextWebLink>
            <br />
            Monday - Friday 8:00 AM - 5:00 PM
            <br />
            Phone: <PhoneLink>435-477-8360</PhoneLink>
          </p>
          <p>
            <b>Office Personnel</b>
            <br />
            Melanie Hall, Deputy Treasurer
            <br />
            Phone: <PhoneLink>435-477-8361</PhoneLink>
            <br />
            <br />
            Nichole Bess, Deputy Treasurer
            <br />
            Phone: <PhoneLink>435-477-8363</PhoneLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} />
      </div>
    </>
  );
}

export default TreasurerSideBar;
