export const Commission2023 = [
  {
    date: "September 9, 2024 ",
    agenda: "https://www.utah.gov/pmn/files/1166769.pdf",
    minutes: "",
    youtube: "",
  },
  {
    date: "September 5, 2024 (Planning) ",
    agenda: "https://www.utah.gov/pmn/files/1160309.docx",
    minutes: "",
    youtube: "",
  },
  {
    date: "September 5, 2024 (Planning) ",
    agenda: "https://www.utah.gov/pmn/files/1160285.docx",
    minutes: "",
    youtube: "",
  },
  {
    date: "August 26, 2024  ",
    agenda: "https://www.utah.gov/pmn/files/1160905.pdf",
    minutes: "",
    youtube: "",
  },
  {
    date: "August 12, 2024  ",
    agenda: "https://www.utah.gov/pmn/files/1155791.pdf",
    minutes: "",
    youtube: "",
  },
  {
    date: "August 12, 2024 (Planning) ",
    agenda: "https://www.utah.gov/pmn/files/1152851.docx",
    minutes: "",
    youtube: "",
  },
  {
    date: "August 12, 2024 (Budget Amendment) ",
    agenda: "https://www.utah.gov/pmn/files/1152833.pdf",
    minutes: "",
    youtube: "",
  },
  {
    date: "August 5, 2024 (Special Meeting) ",
    agenda: "https://www.utah.gov/pmn/files/1152827.pdf",
    minutes: "https://www.utah.gov/pmn/files/1167509.pdf",
    youtube: "",
  },
  {
    date: "August 1, 2024 (Special Meeting) ",
    agenda: "https://www.utah.gov/pmn/files/1151737.pdf",
    minutes: "https://www.utah.gov/pmn/files/1167507.pdf",
    youtube: "",
  },
  {
    date: "July 22, 2024 ",
    agenda: "/files/commission/agenda-07-22.pdf",
    minutes: "https://www.utah.gov/pmn/files/1162287.pdf",
    youtube: "",
  },
  {
    date: "July 22, 2024 (Planning)",
    agenda: "https://www.utah.gov/pmn/files/1146630.docx",
    minutes: "",
    youtube: "",
  },
  {
    date: "July 9, 2024 (Special Meeting)",
    agenda: "https://www.utah.gov/pmn/files/1143927.pdf",
    minutes: "https://www.utah.gov/pmn/files/1158811.pdf",
    youtube: "",
  },
  {
    date: "July 8, 2024",
    agenda: "https://www.utah.gov/pmn/files/1142961.pdf",
    minutes: "https://www.utah.gov/pmn/files/1157009.pdf",
    youtube: "",
  },
  {
    date: "June 24, 2024",
    agenda: "https://www.utah.gov/pmn/files/1138571.pdf",
    minutes: "https://www.utah.gov/pmn/files/1144633.pdf",
    youtube: "",
  },
  {
    date: "June 10, 2024",
    agenda: "https://www.utah.gov/pmn/files/1131829.pdf",
    minutes: "https://www.utah.gov/pmn/files/1139449.pdf",
    youtube: "https://www.youtube.com/watch?v=W4BUrqpzo2g",
  },
  {
    date: "June 6, 2024 (Special Meeting)",
    agenda: "https://www.utah.gov/pmn/files/1122261.pdf",
    minutes: "https://www.utah.gov/pmn/files/1133805.pdf",
    youtube: "",
  },

  {
    date: "June 6, 2024",
    agenda: "https://www.utah.gov/pmn/files/1131111.pdf",
    minutes: "https://www.utah.gov/pmn/files/1133805.pdf",
    youtube: "",
  },
  {
    date: "May 28, 2024",
    agenda: "https://www.utah.gov/pmn/files/1126473.pdf",
    minutes: "https://www.utah.gov/pmn/files/1139447.pdf",
    youtube: "https://www.youtube.com/watch?v=1QQv9DOcIGE",
  },
  {
    date: "May 13, 2024",
    agenda: "https://www.utah.gov/pmn/files/1119743.pdf",
    minutes: "https://www.utah.gov/pmn/files/1128615.pdf",
    youtube: "https://www.youtube.com/watch?v=7DXgsSOfzgs&t=47s",
  },
  {
    date: "April 22, 2024",
    agenda: "https://www.utah.gov/pmn/files/1112307.pdf",
    minutes: "https://www.utah.gov/pmn/files/1122255.pdf",
    youtube: "https://www.youtube.com/watch?v=CbgTLtpsiWM",
  },
  {
    date: "April 8, 2024",
    agenda: "https://www.utah.gov/pmn/files/1106269.pdf",
    minutes: "https://www.utah.gov/pmn/files/1113699.pdf",
    youtube: "https://www.youtube.com/watch?v=xjZt2-piMG0",
  },

  {
    date: "March 25, 2024 ",
    agenda: "https://www.utah.gov/pmn/files/1100761.pdf",
    minutes: "https://www.utah.gov/pmn/files/1107943.pdf",
    youtube: "https://www.youtube.com/watch?v=7Dqu7NrDVnY",
  },
  {
    date: "March 19, 2024  (Special Meeting)",
    agenda: "https://www.utah.gov/pmn/files/1098433.pdf",
    minutes: "https://www.utah.gov/pmn/files/1102261.pdf",
    youtube: "https://www.youtube.com/watch?v=1p7DfVhOKFY",
  },
  {
    date: "March 11, 2024 ",
    agenda: "https://www.utah.gov/pmn/files/1094575.pdf",
    minutes: "https://www.utah.gov/pmn/files/1102259.pdf",
    youtube: "https://www.youtube.com/watch?v=6itmsoGdbhY",
  },
  {
    date: "February 26, 2024 ",
    agenda: "https://www.utah.gov/pmn/files/1089101.pdf",
    minutes: "https://www.utah.gov/pmn/files/1096157.pdf",
    youtube: "https://www.youtube.com/watch?v=gLwA_VQRB-Q&t=1s",
  },
  {
    date: "February 12, 2024 (CIB) ",
    agenda: "https://www.utah.gov/pmn/files/1083947.pdf",
    minutes: "",
    youtube: "",
  },
  {
    date: "February 12, 2024 ",
    agenda: "https://www.utah.gov/pmn/files/1083945.pdf",
    minutes: "https://www.utah.gov/pmn/files/1090493.pdf",
    youtube: "https://www.youtube.com/watch?v=1CE0bivG9f8",
  },
  {
    date: "January 22, 2024 ",
    agenda: "https://www.utah.gov/pmn/files/1073223.pdf",
    minutes: "https://www.utah.gov/pmn/files/1085921.pdf",
    youtube: "https://www.youtube.com/watch?v=J5Xfj0bNVeI",
  },
  {
    date: "January 8, 2024 ",
    agenda: "https://www.utah.gov/pmn/files/1066861.pdf",
    minutes: "https://www.utah.gov/pmn/files/1085919.pdf",
    youtube: "https://www.youtube.com/watch?v=uz7NqjRbbgQ",
  },
];
