import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import PlanningSidebar from "./PlanningSidebar";
import Textbox from "../../../Components/Text/Textbox";
import Title from "../../../Components/Text/Title";
import PublicNoticesTable from "../../../Components/Card/PublicNoticesTable";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function PlanningAgendas() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const notices = [
    {
      title: "September 2024",
      desc: "Planning Commission Agenda. ",
      links: [
        {
          title: "Agenda",
          to: "https://www.utah.gov/pmn/files/1165191.docx",
          isLink: true,
        },
      ],
    },
    {
      title: "August 2024",
      desc: "Planning Commission Agenda. ",
      links: [
        {
          title: "Agenda",
          to: "https://www.utah.gov/pmn/files/1150835.pdf",
          isLink: true,
        },
        {
          title: "Audio",
          to: "https://www.utah.gov/pmn/files/1152575.MP3",
          isLink: true,
        },
      ],
    },
    {
      title: "July 2024",
      desc: "Planning Commission Agenda. ",
      links: [
        {
          title: "Agenda",
          to: "https://www.utah.gov/pmn/files/1141795.pdf",
          isLink: true,
        },
        {
          title: "Minutes",
          to: "https://www.utah.gov/pmn/files/1152581.pdf",
          isLink: true,
        },
        {
          title: "Audio",
          to: "https://www.utah.gov/pmn/files/1143285.MP3",
          isLink: true,
        },
      ],
    },
    {
      title: "June 2024",
      desc: "Planning Commission Agenda. ",
      links: [
        {
          title: "Agenda",
          to: "https://www.utah.gov/pmn/files/1130801.pdf",
          isLink: true,
        },
        {
          title: "Minutes",
          to: "https://www.utah.gov/pmn/files/1143287.pdf",
          isLink: true,
        },
        {
          title: "Audio",
          to: "https://www.utah.gov/pmn/files/1132507.MP3",
          isLink: true,
        },
      ],
    },
    {
      title: "May 2024",
      desc: "Planning Commission Agenda. ",
      links: [
        {
          title: " Agenda",
          to: "https://www.utah.gov/pmn/files/1116603.pdf",
          isLink: true,
        },
        {
          title: " Minutes",
          to: "https://www.utah.gov/pmn/files/1132509.pdf",
          isLink: true,
        },
        {
          title: " Audio",
          to: "https://www.utah.gov/pmn/files/1117823.MP3",
          isLink: true,
        },
      ],
    },
    {
      title: "April 2024",
      desc: "Planning Commission Agenda. ",
      links: [
        {
          title: " Agenda",
          to: "https://www.utah.gov/pmn/files/1106895.docx",
          isLink: true,
        },
        {
          title: " Audio",
          to: "https://www.utah.gov/pmn/files/1106897.MP3",
          isLink: true,
        },
      ],
    },
    {
      title: "March 2024",
      desc: "Planning Commission Agenda. ",
      links: [
        {
          title: " Agenda",
          to: "https://www.utah.gov/pmn/files/1091685.pdf",
          isLink: true,
        },
        {
          title: " Minutes",
          to: "https://www.utah.gov/pmn/files/1106893.pdf",
          isLink: true,
        },
        {
          title: " Audio",
          to: "https://www.utah.gov/pmn/files/1096579.mp3",
          isLink: true,
        },
      ],
    },
    {
      title: "February 2024",
      desc: "Planning Commission Agenda. !!LOCATION CHANGED!! This meeting will be held in Justice Court Room at 82 N 100 E, Cedar City",
      links: [
        {
          title: " Agenda",
          to: "https://www.utah.gov/pmn/files/1078809.pdf",
          isLink: true,
        },
        {
          title: " Audio",
          to: "https://www.utah.gov/pmn/files/1081147.MP3",
          isLink: true,
        },
      ],
    },
    {
      title: "January 2024",
      desc: "Planning Commission Agenda",
      links: [
        {
          title: " Agenda",
          to: "https://www.utah.gov/pmn/files/1065335.pdf",
          isLink: true,
        },
        {
          title: " Minutes",
          to: "https://www.utah.gov/pmn/files/1081151.pdf",
          isLink: true,
        },
        {
          title: " Audio",
          to: "https://www.utah.gov/pmn/files/1067249.MP3",
          isLink: true,
        },
      ],
    },
    {
      title: "December 2023",
      desc: "Planning Commission Agenda",
      links: [
        {
          title: " Agenda",
          to: "https://www.utah.gov/pmn/files/1054027.pdf",
          isLink: true,
        },
        {
          title: "Minutes",
          to: "https://www.utah.gov/pmn/files/1067251.pdf",
          isLink: true,
        },
        {
          title: " Audio",
          to: "https://www.utah.gov/pmn/files/1057721.MP3",
          isLink: true,
        },
      ],
    },
    {
      title: "November 2023",
      desc: "Planning Commission Agenda and Recording",
      links: [
        {
          title: " Agenda",
          to: "https://www.utah.gov/pmn/files/1041027.pdf",
          isLink: true,
        },
        {
          title: "Minutes",
          to: "https://www.utah.gov/pmn/files/1057723.pdf",
          isLink: true,
        },
        {
          title: "Audio",
          to: "https://www.utah.gov/pmn/files/1043861.MP3",
          isLink: true,
        },
      ],
    },
    {
      title: "October 2023",
      desc: "Planning Commission Agenda and Recording",
      links: [
        {
          title: " Agenda",
          to: "https://www.utah.gov/pmn/files/1027281.pdf",
          isLink: true,
        },
        // {
        //   title: "Minutes",
        //   to: "https://www.utah.gov/pmn/files/1031055.pdf",
        //   isLink: true,
        // },
        {
          title: "Audio",
          to: "https://www.utah.gov/pmn/files/1030789.MP3",
          isLink: true,
        },
      ],
    },
    {
      title: "September 2023",
      desc: "Planning Commission Agenda and Recording",
      links: [
        {
          title: " Agenda",
          to: "https://www.utah.gov/pmn/files/1017143.docx",
          isLink: true,
        },
        {
          title: "Minutes",
          to: "https://www.utah.gov/pmn/files/1031055.pdf",
          isLink: true,
        },
        {
          title: "Audio",
          to: "https://www.utah.gov/pmn/files/1022701.MP3",
          isLink: true,
        },
      ],
    },
  ];
  const Left = () => (
    <>
      <Title>Planning Commission Agenda</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <ButtonSingle href="/departments/planning/items">
          Planning Commission Items
        </ButtonSingle>
        <p>
          Meetings held the first Thursday of each month at 5:30 PM Festival
          Hall, 105 N Main St. Room #1 (Check public notices for change of time
          or place.)
        </p>
        <PublicNoticesTable data={notices} />
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Planning: Agendas</title>
        <meta name="description" content="Iron County Planning agendas Page" />
        <link
          rel="canonical"
          href="https://ironcounty.net/departments/planning/agenda"
        />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<PlanningSidebar />}
        id="engineering-background"
        click={click}
      />
    </>
  );
}

export default PlanningAgendas;
