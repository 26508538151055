import React, { useState } from "react";
import DepartmentTemplate from "../../../Components/Department/DepartmentTemplate";
import Title from "../../../Components/Text/Title";
import Textbox from "../../../Components/Text/Textbox";
import FireSidebar from "./FireSidebar";
import Half from "../../../Components/Sizes/Half";
import ButtonSingle from "../../../Components/Buttons/Button_Single";
import Map from "../../../Components/Maps/Map";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

function Fire() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const Left = () => (
    <>
      <Title>Fire</Title>
      <div className="menu-icon-side" onClick={handleClick}>
        {click ? <AiOutlineClose size={35} /> : <AiOutlineMenu size={35} />}
      </div>
      <Textbox>
        <Half>
          <h1>What We Do</h1>
          <p>
            Iron County provides a wide variety of services to the citizens and
            visitors through the fire program. In addition to municipal calls
            such as vehicle and structural fires firefighters also have advanced
            training in Wildland Fire Management, Hazardous Materials Response,
            Cold Water Rescue, Aircraft Rescue Firefighting and High Angle Rope
            Rescue. Firefighters are trained and certified through the Utah Fire
            and Rescue Academy and exceed the minimum requirements for the state
            of Utah.
          </p>
          <p>
            By agreement Iron County contracts fire management services with the
            Utah Division of Forestry, Fire & State Lands and local community
            fire departments.
          </p>
          <p>
            For more information about the fire program, including tours or
            public presentations, or to become involved with your community fire
            department, contact the Fire Warden or your local fire department.
          </p>
        </Half>

        <Half>
          <h1>Fire Restriction Rescindment</h1>

          <p>
            Moderated fire danger indices and improved weather conditions have
            resulted in reduced wildfire hazard in southwest Utah. As a result,
            Stage 1 fire restrictions enacted by Closure Order #SWCLO2402 are
            hereby rescinded within the areas described below beginning August
            30, 2024 at 0001 hours.
          </p>
          <p>
            Stage 1 fire restrictions have been lifted in Iron County. We remain
            in a closed fire season and a burn permit is required for outdoor
            burning in unincorporated areas of Iron County (check with your
            local fire department in cities or towns). Burn Permits can be
            obtained after a site inspection Monday - Thursday between the hours
            of 8am and 5 pm. There is no cost, and to schedule an appointment
            you can contact Ryan Riddle 435-590-4714, Lucas Twitchell
            435-704-4117, or Aaron Mayes 435-691-8563.
          </p>
          <ButtonSingle
            href="/files/departments/fire/fire-restrictions.pdf"
            isLink={true}
          >
            Fire Restriction Rescindment
          </ButtonSingle>
          <ButtonSingle
            href="/files/departments/fire/color-country-news-release.pdf"
            isLink={true}
          >
            Color Country News Release
          </ButtonSingle>
        </Half>
      </Textbox>
      <Textbox>
        <Map src="https://bit.ly/CCFirework"></Map>
      </Textbox>
    </>
  );

  return (
    <>
      <Helmet>
        <title>Fire</title>
        <meta name="description" content="Iron County Fire Page" />
        <link rel="canonical" href="https://ironcounty.net/departments/fire" />
      </Helmet>
      <DepartmentTemplate
        left={<Left />}
        right={<FireSidebar />}
        id="fire-background"
        click={click}
      />
    </>
  );
}

export default Fire;
