import React from "react";
import "../../../Components/Sidebar/Sidebar.css";
import AccordionSidemenu from "../../../Components/AccordionMenu/AccordionSidemenu";
import TextWebLink from "../../../Components/Links/TextWebLink";
import HoursContact from "../../../Components/Text/HoursContact";
import PhoneLink from "../../../Components/Links/PhoneLink";
import Subtitle from "../../../Components/Text/Subtitle";

function ElectionSidebar() {
  const navItems = [
    {
      title: "Clerk Homepage",
      to: "/departments/clerk",
      isLink: false,
    },
    {
      title: "Election Homepage",
      to: "/departments/clerk/elections",
    },
    {
      title: "Ballot Trax",
      to: "https://ballottrax.utah.gov/voter/",
      isLink: true,
    },
    {
      title: "Candidate Information",
      to: "/departments/clerk/candidate-information",
    },
    {
      title: "Candidate Financial Reports",
      to: "/departments/clerk/financial-reports",
    },

    {
      title: "General Notice of Election",
      to: "/files/departments/clerk/elections/general-notice-of-election.pdf",
      isLink: true,
    },
    {
      title: "Results",
      to: "/departments/clerk/election-results",
    },

    // {
    //   title: "Sample Ballot",
    //   to: "/files/departments/clerk/elections/democratic-sample-ballot.pdf",
    //   isLink: true,
    // },
    {
      title: "Utah Voting",
      to: "https://vote.utah.gov/",
      isLink: true,
    },
  ];

  return (
    <>
      <div className="nav-menu-side">
        <HoursContact>
          <p>
            Jon Whittaker, County Clerk
            <br />
            PO Box 429
            <br />
            <TextWebLink link="https://goo.gl/maps/dWeYhAwocsaNHEyT9">
              68 S 100 E Parowan, UT 84761
            </TextWebLink>
            <br />
            Monday - Friday 8:00 A.M. - 5:00 P.M.
            <br />
            <PhoneLink>435-477-8340</PhoneLink>
          </p>
        </HoursContact>
        <br />
        <AccordionSidemenu nav={navItems} />
        <br />
        <div className="hoursContact">
          <Subtitle> 2024 Key Election Dates</Subtitle>

          <li className="no-dots">
            Candidate filing period: January 2nd – January 8th, 8:00 a.m. – 5:00
            p.m.
          </li>
          <li className="no-dots">
            Presidential Preference Primary: March 5th
          </li>
          <li className="no-dots">Primary Election: June 25th</li>
          <li className="no-dots">
            Judge and Justices filing period: July 1st – July 15th, 8:00 a.m. –
            5:00 p.m.
          </li>
          <li className="no-dots">General Election: November 5th</li>
        </div>
        <br />
      </div>
    </>
  );
}

export default ElectionSidebar;
