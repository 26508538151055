import React from "react";
import "../Home.css";
import House from "../../../Images/Home/House.jpg";
import Tax from "../../../Images/Home/tax.jpg";
import campfire from "../../../Images/Home/campfire.jpg";
import Vote from "../../../Images/Home/Vote.jpg";
import Mic from "../../../Images/Home/Mic.jpg";
import Mic2 from "../../../Images/Home/audio-headphones.jpg";
import Election from "../../../Images/Home/election-results.jpg";
import write from "../../../Images/Home/writing.jpg";
import sheriff from "../../../Images/Home/Badge-BTM.png";

import road from "../../../Images/Home/road-sign.jpg";

import TabView from "../../../Components/Tabs/TabView";
import List from "../../../Images/Home/list.png";
import ButtonImage from "../../../Components/Buttons/Button_Image";
import Apply from "./Tabs/Apply";
import Request from "./Tabs/Request";

import Popular from "./Tabs/Popular";
import Pay from "./Tabs/Pay";

import View from "./Tabs/View";

function PopularServices() {
  const sideTabs = [
    {
      name: "Popular Services",
      content: <Popular />,
    },
    {
      name: "Apply",
      content: <Apply />,
    },
    {
      name: "Request",
      content: <Request />,
    },
    {
      name: "Pay",
      content: <Pay />,
    },
    {
      name: "View",
      content: <View />,
    },
  ];

  const seasonal = [
    {
      image: campfire,
      title: "Fire Restriction Rescindment",
      to: "/departments/fire",
      isLink: false,
      textDark: false,
    },
    {
      image: Election,
      title: "General Notice of Election",
      to: "/files/departments/clerk/elections/general-notice-of-election.pdf",
      isLink: true,
      textDark: false,
    },

    {
      image: Tax,
      title: "Abatement/Tax Relief",
      to: "/departments/auditor/individual-abatements",
      isLink: false,
      textDark: false,
    },
  ];
  return (
    <div id="howDoI-container">
      <TabView tabs={sideTabs} size="hundred" />
      <div id="apply-container">
        <div className="buttons-container">
          <ButtonImage buttons={seasonal} />
        </div>
      </div>
    </div>
  );
}

export default PopularServices;
